import { SAppLogo } from './styles';
import { NavLink } from 'react-router-dom';

const AppLogo = () => {
  return (
    <SAppLogo variant="h5" noWrap>
      <NavLink to={ '/' }>
        Giyos Abbaskhanov
      </NavLink>
    </SAppLogo>
  );
};

export default AppLogo;
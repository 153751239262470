import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import AppLogo from 'shared/ui/AppLogo';
import { navLinks } from 'shared/constants/navLinks';
import { DesktopNavbar, MobileNavbar } from 'shared/ui/Navbar';

const LandingHeader: React.FC = () => {
  const [isNavOpen, setIsNavOpen] = React.useState<boolean>(false);

  const handleOpenNavMenu = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <AppBar position="static" elevation={ 0 }>
      <MobileNavbar
        navLinks={ navLinks }
        isNavOpen={ isNavOpen }
        onClickHandler={handleOpenNavMenu}
      />
      <Container
        sx={ { display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 1, py: 2 } }
        maxWidth="xl"
      >
        <Box sx={ { display: { xs: 'none', md: 'initial' } } }>
          <AppLogo />
        </Box>
        <Box sx={ { flexGrow: 1, display: { xs: 'flex', md: 'none' } } }>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={ handleOpenNavMenu }
          >
            <MenuIcon />
          </IconButton>
        </Box>
        <DesktopNavbar navLinks={ navLinks } />
      </Container>
    </AppBar>
  );
};
export default LandingHeader;
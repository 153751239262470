import { keyframes, styled } from '@mui/material';
import Typography from '@mui/material/Typography';

export const SAppLogo = styled(Typography)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontSize: '1rem',
  fontWeight: 600,
  letterSpacing: '.15rem',
  color: 'inherit',
  textDecoration: 'none',
  overflow: 'visible',
  '&:after': {
    content: "''",
    display: 'block',
    backgroundColor: theme.palette.primary.main,
    height: '4px',
    marginTop: theme.spacing(3),
    width: '0%',
    borderRadius: '10px',
    animation: lineAnimation,
    animationDuration: '0.5s',
    animationTimingFunction: 'ease-out',
    animationDelay: '0.5s',
    animationFillMode: 'forwards',
    transition: '0.3s'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.2rem',
    '&:after': {
      marginTop: theme.spacing(1),
    }
  },
  '&:hover': {
    '&:after': {
      width: '80%',
      opacity: 0.2
    }
  }
}))

const lineAnimation = keyframes({
  '0%': {
    width: '0%',
  },
  '100%': {
    width: '112%'
  }
})
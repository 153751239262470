import { TNavLink } from 'shared/constants/navLinks';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

type TDesktopNavbarProps = {
  navLinks: TNavLink[]
}
export const DesktopNavbar = (props: TDesktopNavbarProps) => {
  return (
    <Box sx={ { display: { xs: 'none', md: 'flex' } } }>
      <Tabs
        value={ props.navLinks[0].title }
        textColor="primary"
        indicatorColor="primary"
      >
        { props.navLinks.map((link) => (
          <Tab
            href={ link.path }
            key={ link.path }
            label={ link.title }
            value={ link.title }
          />
        )) }
      </Tabs>
    </Box>
  );
};

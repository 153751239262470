export const landingTexts = {
  aboutMe: {
    introduction: {
      greeting: 'Привет!',
      myNameIs: 'Меня зовут',
      name: 'Гиес',
      description: {
        1: 'Я JS TeamLead и консультант ',
        2: 'для тех ребят, кто уже строит или хочет построить свою карьеру в ',
        3: 'JS разработке'
      }
    },
    experience: {
      title: 'Обо мне',
      description: 'Мой опыт работы в JS более 8 лет,',
      leadingExperience: {
        1: 'а последние ',
        2: '3 года ',
        3: 'я занимаюсь тем, что являюсь ',
        4: 'тим лидом.'
      }
    },
    whatILoveInTheJob: {
      1: 'Больше всего в своей работе мне нравится видеть ',
      2: 'рост команды ',
      3: 'и то, как благодаря совместным усилиям у ребят получается ',
      4: 'достигать различные бизнес цели.'
    },
    whatIsNecessary: {
      1: 'В моей работе для меня важны следующие ',
      2: 'принципы:'
    },
    myPrinciples: {
      peopleWorkWithPeople: {
        1: 'Я считаю, что люди работают с людьми, поэтому необходимо обходиться с людьми так, как человеку хотелось бы, чтобы обходились с ним. ',
        2: 'Важно оставаться человеком. ',
        3: 'Работа, проект, стартап когда-то закончатся, а испорченные отношения с людьми не факт, что получится выправить.'
      },
      codingIsForBusiness: {
        1: 'Для меня кодинг/разработка - это ',
        2: 'инструмент для решения бизнес задач, ',
        3: 'не более и не менее.'
      },
      communicationIsKey: {
        1: 'В современном мире важно не только уметь здорово писать код, но и уметь ',
        2: 'работать в команде, ',
        3: 'общаться с людьми, строить коммуникацию.'
      },
      endlessPotential: {
        1: 'Я считаю, что у каждого человека в команде, с которой я работаю, огромный ',
        2: 'потенциал, ',
        3: 'и моя задача, как лида, помогать людям реализовывать его.'
      }
    }
  },
  howCanIHelp: {
    main: 'Чем я могу помочь',
    assistInGoal: 'Помочь вам достичь ваши карьерные цели в JS;',
    assistInLearning: 'Помочь вам освоить новые навыки в js/фронтэнд разработке;',
    helpPeople: 'Помогать людям, строящим карьеру в JS разработке, в качестве наставника;',
    buildSofts: 'Улучшить софт скиллы, в том числе и навыки коммуникации;',
    realiseCareer: 'Разобрать навыки, необходимые для построения дальнейшей карьеры;',
    testInterview: 'Провести техническое собеседование, разобрать тестовое задание, либо собеседование.'
  },
  pricing: {
    main: 'Стоимость услуг',
    duration: 'Длительность - 1ч.',
    rateIncludes: 'Тариф включает в себя:',
    interviewTesting: [
      'Проведение технического собеседования',
      'Разбора собеседования',
      'Рекомендации к прохождению собеседования',
      'Разбор тестового задания',
      'Рекомендации к тестовому заданию'
    ],
    techConsulting: [
      'Помощь в достижении карьерной цели',
      'Продумывание шагов к достижению цели',
      'Работа над сильными и слабыми сторонами',
      'Рекомендация информации для получения навыков'
    ],
    myMentorship: [
      'Менторство',
      'Наставничество',
      'Встречи раз в неделю (можно согласовать)'
    ],
    myMentorshipDuration: 'Длительность встречи - 1ч.'
  },
  appointment: 'Записаться на консультацию',
  appointmentBookingForm: {
    title: 'Запись на консультацию',
    username: 'Ваше имя',
    userContact: 'Предпочитаемый способ связи',
    date: 'Выберите дату для записи',
    timeTitle: 'Выберите любое доступное время',
    submitForm: 'Записаться',
    writeMe: 'Написать мне',
    requiredField: 'Заполните поле',
    requiredTime: 'Выберите время',
    disabledDate: 'В этот день консультации не проводятся',
    alertSuccess: 'Вы успешно записаны!'
  }
}
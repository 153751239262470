import { css } from '@emotion/react';
import { Theme } from '@mui/material';
import { fontFaces } from './fontFaces';

export const globalStyles = (theme: Theme) => css({
  ...fontFaces,
  '*': {
    boxSizing: 'border-box',
    padding: 0,
    margin: 0,
    fontSize: 'inherit',
    font: 'inherit'
  },
  'a': {
    color: 'inherit',
    textDecoration: 'none'
  },
  html: {
    scrollBehavior: 'smooth'
  },
  body: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightLight,
    overflowX: 'hidden',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  code: {
    fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, \'Courier New\', monospace'
  }
});
import { TNavLink } from 'shared/constants/navLinks';
import AppLogo from 'shared/ui/AppLogo';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box/Box';

type TMobileNavbarProps = {
  navLinks: TNavLink[];
  isNavOpen: boolean;
  onClickHandler: () => void;
}
export const MobileNavbar = (props: TMobileNavbarProps) => {
  return (
    <Paper
      sx={{
        display: { xs: 'flex', md: 'none' },
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: props.isNavOpen ? 220 : 0,
        overflow: 'hidden',
        borderRadius: 0,
        pt: props.isNavOpen ? 5 : 0,
        transition: '0.5s'
      }}
    >
      {props.navLinks.map((page) => (
        <MenuItem key={page.path}>
          <Typography
            href={page.path}
            component={'a'}
            textAlign="center"
            sx={{ textDecoration: 'none', width: 1}}
          >
            {page.title}
          </Typography>
        </MenuItem>
      ))}
      <Box sx={{pt: 5}}>
        <AppLogo />
      </Box>
    </Paper>
  );
};


import React from 'react';
import LandingHeader from 'entities/Header';
import LandingAboutMe from 'widgets/LandingAboutMe';
import LandingMyGoal from 'widgets/LandingMyGoal/LandingMyGoal';
import LandingPrices from 'widgets/LandingPrices/ui/LandingPrices';
import { SLandingPageContainer } from './styles';


interface LandingPageProps {
  // title: string;
  // description: string;
}

const LandingPage: React.FC<LandingPageProps> = () => {
  return (
    <SLandingPageContainer maxWidth={false}>
      <LandingHeader />
      <LandingAboutMe />
      <LandingMyGoal />
      <LandingPrices />
    </SLandingPageContainer>
  );
};

export default LandingPage;
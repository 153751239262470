import { Components, Theme } from '@mui/material';

export const muiComponents: Components<Omit<Theme, 'components'>> = {
  MuiContainer: {
    styleOverrides: {
      root: {
        maxWidth: 1160
      }
    }
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        listStyleType: 'disc',
        display: 'list-item',
        listStyle: 'inside',
        p: {
          display: 'inline'
        },
        '&::marker': {
          color: 'rgba(0,0, 0, 0.3)'
        }
      }
    }
  },
  MuiCard: {
    styleOverrides: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        padding: '40px 25px',
        gap: 10,
        boxShadow: '4px 5px 16px rgba(0, 0, 0, 0.07)'
      }
    }
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: 0,
        '&:last-child': {
          paddingBottom: 0
        }
      }
    }
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: 'transparent',
        color: 'inherit'
      }
    }
  },
  MuiTab: {
    styleOverrides: {
      root: {
        borderRadius: 10
      }
    }
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        '.MuiTabs-indicator': {
          height: 0
        }
      }
    }
  },
  MuiMenu: {
    styleOverrides: {
      root: {
        '.MuiMenu-paper': {
          borderRadius: 15
        }
      }
    }
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        width: 50,
        height: 50
      }
    }
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        b: {
          fontWeight: 400
        }
      }
    }
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        input: {
          padding: '12px 15px'
        }
      }
    }
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        lineHeight: '1rem'
      }
    }
  },
  MuiModal: {
    defaultProps: {
      sx: {
        p: { xs: 3, md: 7 }
      }
    },
    styleOverrides: {
      root: {
        display: 'flex',
        justifyContent: 'center',
        '.MuiDialog-paper': {
          padding: 15
        },
        '.MuiPickersArrowSwitcher-button': {
          marginLeft: 5
        }
      }
    }
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        '.MuiPickersDay-root': {
          fontWeight: 400,
          '&:disabled': {
            opacity: 0.5
          }
        },
        '.MuiMultiSectionDigitalClock-root': {
          width: 150,
          borderTopLeftRadius: 30,
          borderTopRightRadius: 30,
          '.MuiList-root': {
            width: '100%'
          }
        },
        '.MuiPickersLayout-actionBar': {
          '.MuiButtonBase-root': {
            margin: '0 auto'
          }
        }
      }
    }
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        padding: '10px 25px'
      }
    }
  }
};
import PriceCard from 'entities/PriceCard';
import { landingTexts } from 'shared/constants/landingTexts';
import React from 'react';
import Grid from '@mui/material/Grid';
import { getLocale } from 'shared/utils/getLocale';

export const PriceCardList = () => {

  const isLocaleRu = getLocale() === 'ru';

  return (
    <Grid
      display={ 'grid' }
      gap={ {xs: 4, md: 3} }
      gridTemplateColumns={ {xs: '1fr)', md: 'repeat(3, 1fr)'} }
      margin={ {xs: 'auto', md: 'initial'} }
      maxWidth={ {xs: 340, md: 'initial'} }
    >
      <PriceCard
        priceCategory="Менторство"
        price={ isLocaleRu ? '12000 ₽' : '$150' }
        descriptionsMap={ landingTexts.pricing.myMentorship }
        duration={ landingTexts.pricing.myMentorshipDuration }
      />
      <PriceCard
        priceCategory="Консультация"
        oldPrice={isLocaleRu ? '10000 ₽' : '$125'}
        price={ isLocaleRu ? '5000 ₽' : '$60' }
        descriptionsMap={ landingTexts.pricing.techConsulting }
        duration={ landingTexts.pricing.duration }
      />
      <PriceCard
        priceCategory="Собеседование/Тестовое"
        price={ isLocaleRu ? '4000 ₽' : '$50' }
        descriptionsMap={ landingTexts.pricing.interviewTesting }
        duration={ landingTexts.pricing.duration }
      />
    </Grid>
  );
};


import React from 'react';
import { Card, CardContent, List, ListItem, Typography } from '@mui/material';
import { landingTexts } from 'shared/constants/landingTexts';
import { AccentBlock } from 'shared/ui/AccentBlock/AccentBlock';

interface PriceCardProps {
  priceCategory: string;
  descriptionsMap: string[];
  price: string;
  duration: string;
  oldPrice?: string;
}

const PriceCard: React.FC<PriceCardProps> = (props) => {
  const { priceCategory, price, descriptionsMap, duration, oldPrice } = props;

  return (
    <Card sx={ {
      textAlign: 'center',
      boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.15)',
      p: {xs: 3, md: 4},
      fontSize: 12
    } }>
      <CardContent sx={ { height: 1, alignItems: 'center', gap: 1 } }>
        <Typography variant="h5" sx={ { mb: 1 } }>
          { priceCategory }
        </Typography>
        <Typography sx={ { mb: 1 } }>
          { landingTexts.pricing.rateIncludes }
        </Typography>
        <List sx={ { my: 'auto' } }>
          { descriptionsMap.map((description, index) => (
            <ListItem key={index} sx={ { px: 0, py: 0.5 } }>
              <Typography key={ `${ priceCategory }-${ index }` }>
                { description }
              </Typography>
            </ListItem>
          )) }
        </List>
        <AccentBlock>
          <Typography sx={ {
            fontSize: {xs: '1rem', md: '1.1rem'},
            fontWeight: '400'
          } }>
            { price }
          </Typography>
          <Typography
            fontSize={{xs: '0.9rem', md: '0.9rem'}}
            sx={{opacity: 0.8, textDecoration: 'line-through'}}
          >
            { oldPrice }
          </Typography>
        </AccentBlock>
        <Typography  sx={ {mt: {xs: 1, md: 3}}}>
          { duration }
        </Typography>
      </CardContent>
    </Card>
  );
};

export default PriceCard;
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { landingTexts } from 'shared/constants/landingTexts';
import { SIntroductionBox } from './styles';
import { StylizedTitle } from 'shared/ui/StylizedTitle/StylizedTitle';

interface LandingAboutMeProps {
  // priceCategory: string;
  // description: string;
}

const LandingAboutMe = (props: LandingAboutMeProps) => {

  return (
    <Container sx={{mt: {xs: -15, md: -12}}}>
      <SIntroductionBox>
        <Avatar src={'/giyos.webp'} sx={{ width: 200, height: 200 }}/>
        <Box
          display={ 'inline-flex' }
          justifyContent={ 'center' }
          flexWrap={ 'wrap' }
          columnGap={{ xs: 1, md: 3 }}
          rowGap={ 1 }
        >
          <Typography variant="h1" width={ { xs: '100%', md: 'initial' } }>
            { landingTexts.aboutMe.introduction.greeting }
          </Typography>
          <Typography variant="h1" color="secondary.main" fontWeight={ 'fontWeightLight' }>
            { landingTexts.aboutMe.introduction.myNameIs }
          </Typography>
          <Typography variant="h1">
            { landingTexts.aboutMe.introduction.name }
          </Typography>
        </Box>
        <Typography variant="subtitle2" maxWidth={800}>
          <b>{ landingTexts.aboutMe.introduction.description[1] }</b>
             { landingTexts.aboutMe.introduction.description[2] }
          <b>{ landingTexts.aboutMe.introduction.description[3] }</b>
        </Typography>
        <Button
          size={ 'large' }
          target={ '_blank' }
          href={ 'https://t.me/giyosabbaskhanov' }
        >
          { landingTexts.appointmentBookingForm.writeMe }
        </Button>
      </SIntroductionBox>
      <Box textAlign={ {xs: 'center', md: 'initial'} }>
        <StylizedTitle id="about-me">
          { landingTexts.aboutMe.experience.title }
        </StylizedTitle>
        <Typography fontWeight={ 'fontWeightRegular' }>
          { landingTexts.aboutMe.experience.description }
        </Typography>
      </Box>
      <Stack
        mb={ {xs: 2, md: 3} }
        gap={ {xs: 2, md: 4} }
        maxWidth={900}
      >
        <Typography>
          { landingTexts.aboutMe.experience.leadingExperience[1] }
          <b>{ landingTexts.aboutMe.experience.leadingExperience[2] }</b>
          { landingTexts.aboutMe.experience.leadingExperience[3] }
          <b>{ landingTexts.aboutMe.experience.leadingExperience[4] }</b>
        </Typography>
        <Typography>
          { landingTexts.aboutMe.whatILoveInTheJob[1] }
          <b>{ landingTexts.aboutMe.whatILoveInTheJob[2] }</b>
          { landingTexts.aboutMe.whatILoveInTheJob[3] }
          <b>{ landingTexts.aboutMe.whatILoveInTheJob[4] }</b>
        </Typography>
        <Typography>
          { landingTexts.aboutMe.whatIsNecessary[1] }
          <b>{ landingTexts.aboutMe.whatIsNecessary[2] }</b>
        </Typography>
      </Stack>
      <List sx={{maxWidth: 900}}>
        <ListItem>
          <Typography>
               { landingTexts.aboutMe.myPrinciples.peopleWorkWithPeople[1] }
            <b>{ landingTexts.aboutMe.myPrinciples.peopleWorkWithPeople[2] }</b>
               { landingTexts.aboutMe.myPrinciples.peopleWorkWithPeople[3] }
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
               { landingTexts.aboutMe.myPrinciples.endlessPotential[1] }
            <b>{ landingTexts.aboutMe.myPrinciples.endlessPotential[2] }</b>
               { landingTexts.aboutMe.myPrinciples.endlessPotential[3] }
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
               { landingTexts.aboutMe.myPrinciples.codingIsForBusiness[1] }
            <b>{ landingTexts.aboutMe.myPrinciples.codingIsForBusiness[2] }</b>
               { landingTexts.aboutMe.myPrinciples.codingIsForBusiness[3] }
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            { landingTexts.aboutMe.myPrinciples.communicationIsKey[1] }
            { landingTexts.aboutMe.myPrinciples.communicationIsKey[2] }
            { landingTexts.aboutMe.myPrinciples.communicationIsKey[3] }
          </Typography>
        </ListItem>
      </List>
    </Container>
  );
};

export default LandingAboutMe;
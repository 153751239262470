import { styled } from '@mui/material';
import Container from '@mui/material/Container';

export const SLandingPageContainer = styled(Container)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  padding: 0,
  fontSize: theme.typography.body1.fontSize,
  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(10),
  }
}))
import { createTheme } from '@mui/material';
import { muiComponents } from '../mui/muiComponents';

export const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#196a7e',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#DBDBDB',
      light: '#E8E8E8'
    },
    success: {
      main: '#6cbd6c',
      contrastText: '#FFFFFF'
    },
    error: {
      main: '#bd6c6c',
      contrastText: '#FFFFFF'
    },
    text: {
      primary: '#262626'
    },
    background: {
      paper: '#ffffff',
      default: '#ffffff'
    },
    divider: '#F7F7F7'
  },
  shape: {
    borderRadius: 25
  },
  typography: {
    fontFamily: '"Open Sans", sans-serif',
    fontWeightBold: 600,
    fontWeightRegular: 400,
    fontWeightLight: 300,
    fontSize: 18,
    htmlFontSize: 18
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        color: 'primary'
      },
      styleOverrides: {
        root: {
          padding: '12px 30px',
          textTransform: 'none',
          whiteSpace: 'nowrap',
          fontSize: '1rem',
          fontWeight: 300,

          '&:disabled': {
            backgroundColor: 'rgba(0, 0 , 0, 0.1)',
            color: 'rgba(0, 0, 0, 0.6)'
          }
        },
        containedSizeLarge: ({ theme }) =>
          theme.unstable_sx({
            p: { xs: '12px 25px', md: '15px 30px' },
            fontSize: { xs: '0.9rem', md: '1rem' },
            borderRadius: 2,
          })
      }
    },
    ...muiComponents
  }
});

// font settings
defaultTheme.typography.h1 = {
  fontWeight: 600,
  fontSize: '2rem',
  display: 'inline',
  [defaultTheme.breakpoints.up('md')]: {
    fontSize: '4rem'
  }
};

defaultTheme.typography.h3 = {
  fontSize: '1.8rem',
  fontWeight: 400,
  [defaultTheme.breakpoints.up('md')]: {
    fontSize: '2.5rem'
  }
};

defaultTheme.typography.h4 = {
  fontSize: '1.5rem',
  fontWeight: 400,
  [defaultTheme.breakpoints.up('md')]: {
    fontSize: '2.0rem'
  }
};

defaultTheme.typography.h5 = {
  fontSize: '1.2rem',
  fontWeight: 400,
  [defaultTheme.breakpoints.up('md')]: {
    fontSize: '1.5rem'
  }
};

defaultTheme.typography.subtitle1 = {
  fontSize: '0.9rem',
  lineHeight: 1.5,
  [defaultTheme.breakpoints.up('md')]: {
    fontSize: '1.2rem'
  }
};

defaultTheme.typography.subtitle2 = {
  fontSize: '1rem',
  lineHeight: 1.5,
  [defaultTheme.breakpoints.up('md')]: {
    fontSize: '1.5rem'
  }
};

defaultTheme.typography.body1 = {
  fontSize: '0.9rem',
  lineHeight: 1.5,
  [defaultTheme.breakpoints.up('md')]: {
    fontSize: '1.13rem'
  }
};
import OpenSansLight from 'shared/assets/fonts/OpenSans/OpenSans-Light.woff2';
import OpenSansRegular from 'shared/assets/fonts/OpenSans/OpenSans-Regular.woff2';
import OpenSansSemiBold from 'shared/assets/fonts/OpenSans/OpenSans-SemiBold.woff2';

export const fontFaces = [
  {
    '@font-face': {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 300,
      fontDisplay: 'swap',
      src: `
      local('Open Sans Light'),
      url(${ OpenSansLight }) format('woff2')
    `
    }
  },
  {
    '@font-face': {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontDisplay: 'swap',
      src: `
      local('Open Sans'),
      url(${ OpenSansRegular }) format('woff2')
    `
    }
  },
  {
    '@font-face': {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontDisplay: 'swap',
      src: `
      local('Open Sans SemiBold'),
      url(${ OpenSansSemiBold }) format('woff2')
    `
    }
  }
];


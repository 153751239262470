import React, { Suspense } from 'react';
import PageLoader from 'shared/ui/PageLoader';
import { RouterProvider } from 'react-router-dom';
import router from 'shared/config/routeConfig/routeConfig';
import { ThemeProvider } from '@mui/material';
import { defaultTheme } from 'app/styles/themes/defaultTheme';
import GlobalStyles from '@mui/material/GlobalStyles';
import { globalStyles } from 'app/styles/global';

function App() {
  return (
    <ThemeProvider theme={ defaultTheme }>
      <GlobalStyles styles={ globalStyles } />
      <Suspense fallback={ <PageLoader /> }>
        <RouterProvider router={ router } />
      </Suspense>
    </ThemeProvider>
  );
}

export default App;

import Typography, { TypographyProps } from '@mui/material/Typography/Typography';

type TStylizedTitleProps = TypographyProps & {
  id?: string;
  children: React.ReactNode;
}
export const StylizedTitle = ({ children, id, ...typographyProps }: TStylizedTitleProps) => {
  return (
    <Typography
      id={id}
      variant="h4"
      sx={ {
        textAlign: {xs: 'center', md: 'initial'},
        letterSpacing: '0.205em',
        mb: { xs: 2, md: 5 },
        scrollMarginTop: 25,
        '&:after': {
          content: '""',
          display: 'block',
          width: 90,
          mx: {xs: 'auto', md: 'initial'},
          mt: { xs: 1, md: 2 },
          height: { xs: 5, md: 10 },
          borderRadius: 10,
          bgcolor: 'secondary.light'
        }
      } }
      { ...typographyProps }
    >
      { children }
    </Typography>
  );
};


export type TNavLink = {
  title: string,
  path: string
}
export const navLinks: TNavLink[] = [
  { title: 'Обо мне', path: '#about-me' },
  { title: 'Услуги', path: '#services' },
  { title: 'Цены', path: '#prices' }
];

export const settings = ['В разработке.', 'И это тоже..', 'Тоже скоро...'];

import Stack from '@mui/material/Stack';

type TAccentBlockProps = {
  children: React.ReactNode
}
export const AccentBlock = ({ children }: TAccentBlockProps) => {
  return (
    <Stack sx={{
      flexDirection: 'row',
      alignItems: 'baseline',
      gap: 1,
      bgcolor: 'divider',
      px: {xs: 2.5, md: 3.5},
      py: {xs: 1, md: 1.5},
      borderRadius: 1,

    }}
    >
      { children }
    </Stack>
  );
};


import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { StylizedTitle } from 'shared/ui/StylizedTitle/StylizedTitle';
import { landingTexts } from 'shared/constants/landingTexts';
import { PriceCardList } from './components/PriceCardList';

interface LandingPricesProps {
  // priceCategory: string;
  // description: string;
}

const LandingPrices: React.FC<LandingPricesProps> = () => {

  return (
    <Container>
      <Box>
        <StylizedTitle id='prices'>
          { landingTexts.pricing.main }
        </StylizedTitle>
        <PriceCardList />
      </Box>
      <Box
        display={ 'flex' }
        flexDirection={ 'column' }
        alignItems={ 'center' }
        gap={ 5 }
        sx={ {
          mx: 'auto',
          my: { xs: 5, md: 10 },
          width: 'min-content'
      } }
      >
        <Button
          size={ 'large' }
          target={ '_blank' }
          href={ 'https://t.me/giyosabbaskhanov' }
        >
          { landingTexts.appointmentBookingForm.writeMe }
        </Button>
      </Box>
    </Container>
  );
};

export default LandingPrices;
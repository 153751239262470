import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { landingTexts } from "shared/constants/landingTexts";
import { StylizedTitle } from 'shared/ui/StylizedTitle/StylizedTitle';

interface LandingMyGoalProps {
  // priceCategory: string;
  // description: string;
}

const LandingMyGoal: React.FC<LandingMyGoalProps> = () => {
  return (
    <Container>
      <Box>
        <StylizedTitle id='services'>
          {landingTexts.howCanIHelp.main}
        </StylizedTitle>
        <List sx={{p: 0}} >
          <ListItem>
            <Typography>
              {landingTexts.howCanIHelp.helpPeople}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              {landingTexts.howCanIHelp.assistInGoal}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              {landingTexts.howCanIHelp.assistInLearning}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              {landingTexts.howCanIHelp.realiseCareer}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              {landingTexts.howCanIHelp.buildSofts}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              {landingTexts.howCanIHelp.testInterview}
            </Typography>
          </ListItem>
        </List>
      </Box>
    </Container>
  );
};

export default LandingMyGoal;
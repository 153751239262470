import { Box, styled } from '@mui/material';

export const SIntroductionBox = styled(Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  gap: 30,
  padding: '100px 0',
  [theme.breakpoints.up('md')]: {
    gap: 45,
  }
}))